import style from './BuySell.module.scss';

const BuySell = () => {
  return (
    <div className={style.buy}>
      <div className='container'>
        <div className={style.buyBody}>
          <div className={style.buyBody__info}>
            <div className={style.buyBody__row}>
              <div className={style.buyBody__rowTitle}>Buy</div>
              <div className={style.buyBody__rowValue}>0% Forever</div>
            </div>
            <div className={style.buyBody__row}>
              <div className={style.buyBody__rowTitle}>Sell</div>
              <div className={style.buyBody__rowValue}>
                Capped at maximum 2% (can only be reduced)
              </div>
            </div>
          </div>
          <div className={style.buyBody__divider}></div>
          <div className={style.buyBody__text}>
            Every sell transaction will be taxed 2%, which will be directly
            swapped for ETH and sent to the treasury wallet. The treasury wallet
            will be used to power various investments across multiple investment
            vehicles, ensuring a balanced and diversified portfolio. Treasury
            profits will be used to sustain DHF price (through strategic
            buybacksand liquidity adds), Power Dividend pools for xDHF holders,
            Compound treasury holdings and to pay out analysts bounties
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySell;
