import { textContent } from "../../textContent";
import style from "./Text.module.scss";

const Text = () => {
  return (
    <div className={style.text}>
      <div className={style.text__label}>{textContent.label}</div>
      <div className={style.text__title}>
        <span>{textContent.title}</span>Wealth
      </div>
      <div className={style.text__subtitle}>
        {textContent.subtitle}
        <div className={style.text__subtitle1}>{textContent.subtitle1}</div>
      </div>
    </div>
  );
};

export default Text;
