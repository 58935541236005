export const cards = [
  {
    title: "High Yields",
    description:
      "Grow your funds with DHF's smart market play, no experience needed.",
  },
  {
    title: "Expertise",
    description:
      "Your investment, managed by our finance gurus, means profit without the effort.",
  },
  {
    title: "Flexibility",
    description:
      "Tailor your investment experience with our innovative Investment Pools , catering to diverse risk appetites. ( Coming Soon )",
  },
  {
    title: "Passive Income",
    description:
      "Earn money while you sleep, with gains directly from DHF's financial maneuvers.",
  },
  {
    title: "Stability",
    description:
      "Count on DHF for steady growth in the dynamic world of crypto.",
  },
  {
    title: "Transparency",
    description:
      "With DHF, what you see is what you get—clear, honest investment progress.",
  },
];
