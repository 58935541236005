import {svgIcons} from "../../assets/svgIcons";

export const socialLinks = [
    {
        href: "#",
        icon_white: svgIcons.twitter_white,
        icon_dark: svgIcons.twitter_dark,
    },
    {
        href: "#",
        icon_white: svgIcons.telegram_white,
        icon_dark: svgIcons.telegram_dark,
    },
    {
        href: "#",
        icon_white: svgIcons.discord_white,
        icon_dark: svgIcons.discord_dark,
    },
    {
        href: "#",
        icon_white: svgIcons.medium_white,
        icon_dark: svgIcons.medium_dark,
    },
]
