export const list = [
    {
        value: 30,
        label: "Distributed to the community"
    },
    {
        value: 30,
        label: "Used for marketing and development purposes"
    },
    {
        value: 30,
        label: "Reinvested in the DHF treasury"
    },
    {
        value: 10,
        label: "DHF analysts and advisors"
    },
]
