import { useState, useEffect } from "react";
import Button from "../../../Button/Button";

import { LightSvg } from "../../assets/light";
import style from "./Calculator.module.scss";

const Calculator = () => {
  const [period, setPeriod] = useState(14);
  const [percentage, setPercentage] = useState(8);
  const [tokenType, setTokenType] = useState("DHF");
  const [invested, setInvested] = useState({
    tokens: "100,000",
    usd: "644.42",
  });
  const [earn, setEarn] = useState({
    tokens: "8,000",
    usd: "40.08",
  });

  return (
    <div className={style.calculator}>
      <div className={style.calculatorInfo}></div>
      <div className={style.calculatorBtns}>
        <Button
          isBlue
          className={style.calculatorBtns__item}
          title="Buy DHF on Uniswap"
        />
        <Button className={style.calculatorBtns__item} title="Telegram" />
      </div>
    </div>
  );
};

export default Calculator;
