import { svgIcons } from "../../assets/svgIcons";

export const textContent = {
  label: "your shortcut to",
  title: "Crypto",
  subtitle:
    "DHF offers an innovative DeFi experience where your investments grow with market-leading strategies.",
  subtitle1:
    "Enjoy the simplicity of crypto growth without any complex steps—hold DHF tokens and benefit from the expertise of our seasoned financial analysts!",
  // ... other properties remain unchanged
  socialLinks: [
    {
      href: "#",
      icon_white: svgIcons.twitter_white,
      icon_dark: svgIcons.twitter_dark,
    },
    {
      href: "#",
      icon_white: svgIcons.telegram_white,
      icon_dark: svgIcons.telegram_dark,
    },
    {
      href: "#",
      icon_white: svgIcons.discord_white,
      icon_dark: svgIcons.discord_dark,
    },
    {
      href: "#",
      icon_white: svgIcons.medium_white,
      icon_dark: svgIcons.medium_dark,
    },
  ],
  calculator: {
    title: "How much will I earn?",
    info: [
      { label: "APR", value: "4% APR" },
      { label: "DHF Invested", value: "100,000 DHF ($644.42 USD)" },
      { label: "After 1 year you will earn", value: "4,000 DHF($25.78 USD)" },
    ],
  },
};
