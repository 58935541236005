import { svgIcons } from '../../assets/svgIcons';

export interface ISlide {
  name: string;
  job: string;
  description: string;
  socials: {
    icon: JSX.Element;
    href: string;
  }[];
}

export const slides: ISlide[] = [
  {
    name: "Ryder",
    job: "Co-Founder",
    description:
      "The lead developer and visionary of DHF, has extensive experience building on EVM-based blockchains.",
    socials: [
      {
        icon: svgIcons.telegram_dark,
        href: "#",
      },
    ],
  },
  {
    name: "Alex",
    job: "Co-Founder",
    description:
      "The lead developer and visionary of DHF, has extensive experience building on EVM-based blockchains.",
    socials: [
      {
        icon: svgIcons.telegram_dark,
        href: "#",
      },
    ],
  },
  {
    name: "Oliver",
    job: "Strategy & Finance",
    description:
      "With years of experience, he serves as the operations manager, handling a diverse range of responsibilities.",
    socials: [
      {
        icon: svgIcons.telegram_dark,
        href: "#",
      },
    ],
  },
  {
    name: "Alberto",
    job: "Business Development",
    description:
      "Crypto Designer is a talented designer with a passion for creating innovative visuals",
    socials: [
      {
        icon: svgIcons.telegram_dark,
        href: "#",
      },
    ],
  },
];
